.paper {
    max-width: 550px;
    width: calc(100% - 32px);
    margin: auto;
    margin-top: 65px;
    padding: 16px 32px;
}

.googlePaper {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid rgb(218, 218, 218);
    border-style: dotted;
    box-shadow: none;
}

.title {
    padding: 30px;
}

#loginUsername, #loginPassword {
    width: 500px;
}

#gmailButton:hover {
    cursor: pointer;
}

.error {
    margin-bottom: 12px;
    color: #ff3f3f;
}

.resetPasswordMsg {
    text-align: center;
    margin: 25px;
    color: #123f5a;
    cursor: pointer;
}

.recoveryMsg {
    color: #278c27;
    margin-bottom: 25px;
}