#openseadragon circle {
    /* Add fill width opacity 0 instead of none to keep mouse interaction */
    fill: #0bf;
    fill-opacity: 0.5;
}

#openseadragon circle:hover {
    fill: #fdd835;
    cursor: pointer;
}

#openseadragon .product-marker {
    fill: #fbd351;
}

#openseadragon .product-marker:hover {
    fill: #8eacab;
    cursor: pointer;
}